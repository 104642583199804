import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Section from "../../components/Section"

const CareersImages = () => {
  return (
    <>
      <Section className="overflow-hidden ">
        <div className="flex items-center justify-center">
          <div className="flex gap-6 w-[300vw] tablet:w-[200vw]  laptop:w-[125vw]  flex-shrink-0 animate-travelMobile laptop:animate-travelLaptop ">
            <StaticImage
              src="../../images/careers-1.png"
              alt=""
              className="rounded-3xl"
            />
            <StaticImage
              src="../../images/careers-2.png"
              alt=""
              className="rounded-2xl"
            />
            <StaticImage
              src="../../images/careers-3.png"
              alt=""
              className="rounded-3xl"
            />
            <StaticImage
              src="../../images/careers-4.png"
              alt=""
              className="rounded-2xl"
            />
            <StaticImage
              src="../../images/careers-5.png"
              alt=""
              className="rounded-3xl"
            />
            <StaticImage
              src="../../images/careers-6.png"
              alt=""
              className="rounded-2xl"
            />
          </div>
        </div>
      </Section>
    </>
  )
}

export default CareersImages
