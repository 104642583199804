import React from "react"
import {
  ArrowClockwise,
  BarChart,
  Calendar4,
  Clock,
  ColumnsGap,
  Globe,
} from "react-bootstrap-icons"

import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import SectionText from "../../components/SectionText"
import FeatureCard1 from "../../components/cards/FeatureCard1"

const CareerFeature = () => {
  return (
    <>
      <Section className="">
        <Container>
          {/* section-wrap */}
          <div className="flex flex-col justify-center items-center gap-14 tablet:gap-[82px]">
            {/* top-part */}
            <div className="">
              <div
                data-aos="fade-in"
                className="flex flex-col items-center justify-center"
              >
                <SectionTitle className="text-center">
                  Company <span className="text text-primary-600">perks </span>
                </SectionTitle>

                <SectionText className="max-w-[732px] !pb-0 text-center">
                  Completely synergize resource taxing relationships via premier
                  niche markets. Professionally cultivate one-to-one customer
                  service with robust ideas.
                </SectionText>
              </div>
            </div>

            {/* bottom-part */}
            <div className="">
              {/* cards */}
              <div className="grid grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-3 gap-[52px]  ">
                {/* card */}

                <FeatureCard1
                  title="Work from Anywhere"
                  text="Synergistically deliver performance based methods of empowerment whereas distributed expertise."
                  icon={<Globe />}
                ></FeatureCard1>

                <FeatureCard1
                  title="Get Paid Time Off"
                  text="Globally myocardinate interactive supply chains with distinctive quality vectors. interoperable services."
                  icon={<Calendar4 />}
                  iconClassName="shadow-s-md text-secondary-600"
                ></FeatureCard1>

                <FeatureCard1
                  title="Paid parental leave"
                  text="Dramatically disseminate standardized metrics after resource-leveling processes meta-services."
                  icon={<ArrowClockwise />}
                  iconClassName="!shadow-p-md !text-primary-600"
                ></FeatureCard1>

                <FeatureCard1
                  title="Learn & Develop"
                  text="Energistically myocardinate clicks-and-mortar testing procedures whereas next-generation manufactured products."
                  icon={<ColumnsGap />}
                  iconClassName="!shadow-p-md !text-primary-600"
                ></FeatureCard1>

                <FeatureCard1
                  title="Flexible hours"
                  text="Appropriately empower dynamic leadership skills after business portals. Globally myocardinate interactive supply."
                  icon={<Clock />}
                  iconClassName="!shadow-s-md text-warning-600"
                ></FeatureCard1>

                <FeatureCard1
                  title="Progress Your Career"
                  text="Seamlessly empower fully researched growth strategies and interoperable internal or organic sources."
                  icon={<BarChart />}
                  iconClassName="shadow-p-md text-success-600"
                ></FeatureCard1>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default CareerFeature
