import React from "react"
import { Link } from "gatsby"

import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import Button from "../../components/Button"

const ListingCard = ({
  title = "Back-End Developer",
  type1 = "Remote",
  type2 = "Full-time",
}) => {
  return (
    <>
      <div
        data-aos="fade-up"
        className="flex flex-col items-start justify-between w-full gap-8 px-6 py-6 bg-white tablet:items-center tablet:flex-row rounded-2xl"
      >
        {/* texts */}
        <div className="flex flex-col gap-4">
          <h6 className="font-bold text-neutral-800 text-display-xs">
            {title}
          </h6>
          <div className="flex gap-6">
            <p className="text-body-md text-neutral-500">{type1}</p>
            <p className="text-body-md text-neutral-500">{type2}</p>
          </div>
        </div>

        <Link to="/career-details">
          <Button
            className="bg-primary-50 !text-neutral-800 border border-primary-50 hover:!text-white"
            size={"lg"}
          >
            View position
          </Button>
        </Link>
      </div>
    </>
  )
}

const CareersListing = () => {
  return (
    <>
      <Container className="bg-primary-50 rounded-3xl">
        {/* section-wrap */}
        <Section>
          <div className="flex flex-col justify-center items-center gap-14 laptop:gap-[82px] ">
            <SectionTitle className="!text-primary-600">
              Current <span className="text-neutral-800 !pb-0">openings</span>
            </SectionTitle>
            {/* cards-wrap */}
            <div className="flex flex-col gap-[62px] max-w-[800px] mx-auto w-full tablet:px-8">
              {/* cards */}
              <div className="flex flex-col gap-7">
                <p
                  data-aos="fade-in"
                  className="font-medium text-body-lg text-primary-600"
                >
                  Development
                </p>
                {/* card */}
                <ListingCard
                  title="Back-End Developer"
                  type1="Remote"
                  type2="Full-time"
                ></ListingCard>
                <ListingCard
                  title="Junior Graphics Designer"
                  type1="Dubai"
                  type2="Part-time"
                ></ListingCard>
              </div>
              <div className="flex flex-col gap-7">
                <p
                  data-aos="fade-in"
                  className="font-medium text-body-lg text-primary-600"
                >
                  Design
                </p>
                {/* card */}
                <ListingCard
                  title="Lead Product Designer"
                  type1="Remote"
                  type2="Full-time"
                ></ListingCard>
                <ListingCard
                  title="Junior Graphics Designer"
                  type1="Dubai"
                  type2="Part-time"
                ></ListingCard>
              </div>
              <div className="flex flex-col gap-7">
                <p
                  data-aos="fade-in"
                  className="font-medium text-body-lg text-primary-600"
                >
                  Business
                </p>
                {/* card */}
                <ListingCard
                  title="Customer Support"
                  type1="Remote"
                  type2="Full-time"
                ></ListingCard>
                <ListingCard
                  title="Marketing Manager"
                  type1="USA"
                  type2="Part-time"
                ></ListingCard>
              </div>
            </div>
          </div>
        </Section>
      </Container>
    </>
  )
}

export default CareersListing
