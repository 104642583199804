import React from "react"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { Link } from "gatsby"

import PageWrapper from "../components/PageWrapper"
import InnerHero from "../components/InnerHero"
import Button from "../components/Button"
import CareersImage from "../sections/careers/CareersImages"
import CareersListing from "../sections/careers/CareersListing"
import CareersFeatures from "../sections/careers/CareersFeatures"

const CareersPage = ({ data }) => {
  return (
    <>
      <PageWrapper
        footerConfig={{
          hasMiddleSocial: true,
          hasBottomSubscription: true,
          bottomClassName: "bg-primary-25 ",
          className: "!bg-neutral-50",
        }}
      >
        <GatsbySeo
          title={`Kloft - Careers 01`}
          description={`We serve globally to modernise static sites to take advantage of the blazing speeds of JAMStack while eliminating monthly maintenance and even probably, server costs.`}
        />

        <InnerHero title="Careers" className="">
          <Link to="#CareersListing">
            <Button className="mt-8" size={"2xl"}>
              See openings
            </Button>
          </Link>
        </InnerHero>

        <CareersImage />
        <div id="CareersListing">
          <CareersListing />
        </div>
        <CareersFeatures />
      </PageWrapper>
    </>
  )
}

export default CareersPage
