import React from "react"

import SectionText from "../SectionText"
import TextSmall from "../TextSmall"

const FeaturCard1 = ({
  title = "John Doe",
  text = "UI Designer",
  icon,
  iconClassName = "",
}) => {
  return (
    <>
      <div
        data-aos="fade-up"
        className="max-w-[300px] tablet:max-w-none flex flex-col gap-8 px-8 py-8 border rounded-2xl border-neutral-200"
      >
        <div
          className={`h-[52px] w-[52px] flex justify-center items-center rounded-lg bg-white shadow-p-md text-success-600 text-display-xs ${iconClassName} `}
        >
          {icon}
        </div>
        {/* card-texts */}
        <div className="flex flex-col items-start justify-start gap-3">
          <SectionText
            isHero="false"
            className="!font-medium !text-neutral-700 !pb-0"
          >
            {title}
          </SectionText>
          <TextSmall>{text}</TextSmall>
        </div>
      </div>
    </>
  )
}
export default FeaturCard1
